import "./SectionHeader.scss";

export default function SectionHeader({
	number,
	title
}: {
	number: number,
	title: string
}){
	let numberText = number.toFixed(0);
	if (numberText.length == 1){
		numberText = `0${numberText}`;
	}
	return (
		<div className="section-header">
			<span className="number">{numberText}.&nbsp;</span>
			<span className="title">{title}</span>
		</div>
	);
}