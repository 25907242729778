import SectionHeader from "../Shared/SectionHeader";
import "./AboutMe.scss";
import Timeline from "./Timeline/Timeline";

export default function AboutMe(){
	return (
		<section className="about-me" id="about-me">
			<div className="content">
				<SectionHeader
					number={1}
					title={"About Me"}
				/>
				<div className="bubble-row">
					<div className="bubble">
						<div className="tmp-image"/>
						<img src="/Headshot.png" /> 
					</div>
					<div className="timeline-container d-none d-md-flex">
						<Timeline/>
					</div>
				</div>
				<div className="blurb"> I work in philosophy of science, on the foundations of spacetime theories and the use of simulations in astrophysics.
				</div>
				<div>
					  
				</div>
				<div>
				I'm currently a Postdoctoral Fellow at the <a href="https://bhi.fas.harvard.edu/">Black Hole Initiative</a> at Harvard University and co-lead of the Foundations Focus Group in the <a href="https://www.ngeht.org/hpc">History Philosophy and Culture Working Group </a> of the Next Generation Event Horizon Telescope Collaboration. Starting in Fall 2025, I'll be an Assistant Professor of Philosophy and Physics at the University of Cincinnati. I received my Ph.D. from the <a href="https://www.lps.uci.edu/">Department of Logic and Philosophy of Science</a> at the University of California, Irvine in Summer 2023 under the supervision of <a href="https://jamesowenweatherall.com/"> Jim Weatherall </a>. 
				</div>
			</div>
		</section>
	);
}