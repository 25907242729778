import { useEffect, useRef } from "react";
import SimulationInitializer from "./engine/SimulationInitializer";
import SimulationLoop from "./engine/SimulationLoop";
import SimulationRenderer from "./engine/SimulationRenderer";
import SimulationState, { SimulationParticle } from "./engine/SimulationState";
import Simulator from "./engine/Simulator";
import "./Simulation.scss";

export default function Simulation(){
	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		if (canvasRef.current == null){
			console.warn("Failed to start simulation - canvas ref was null");
			return;
		}
		const canvas = canvasRef.current;

		const state = new SimulationState(canvas);
		SimulationInitializer.initialize(state, canvas);

		const renderer = new SimulationRenderer(canvas);
		const simulator = new Simulator(renderer, state);
		const loop = new SimulationLoop(simulator);
		loop.run();

		return () => loop.stop();
	});

	return (
		<canvas
			className="simulation"
			ref={canvasRef}/>
	);
}