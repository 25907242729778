import AboutMe from './components/AboutMe/AboutMe';
import AboutResearch from './components/AboutResearch/AboutResearch';
import AboutTeaching from './components/AboutTeaching/AboutTeaching';
import PageSection from './components/Shared/PageSection/PageSection';
import SplashPage from './components/SplashPage/SplashPage';
import Footer from './components/Footer/Footer';
import "./scss/site.scss";

export default function App() {
	return (
		<div className="App">
			<SplashPage />
			<PageSection>
				<AboutMe />
				<AboutResearch />
				<AboutTeaching />
			</PageSection>
			<PageSection className='space'>
				<Footer />
			</PageSection>
		</div>
	);
}