import SectionHeader from "../Shared/SectionHeader";
import "./AboutTeaching.scss";

export default function AboutTeaching(){
	return (
		<section className="about-teaching" id="about-teaching">
			<div className="content">  
				<SectionHeader
						number={3}
						title={"About Teaching"}
					/>
				<br/>
				<div className="head"> course that I've taught </div>
				<br/>
				<div className="text"> Making Modern Science (Summer 2021), on the history and philosophy of science (<a href="/MMSsyllabus.pdf" download="MakingModernScienceSyllabus_Meskhidze.pdf">syllabus</a>) </div> 
				<br/>

				<div className="text"> Introduction to Symbolic Logic (Spring 2023, Spring 2021), on formal tools for argument evaluation (<a href="/SLsyllabus.pdf" download="IntroSymbolicLogicSyllabus_Meskhidze.pdf">syllabus </a>, <a href="https://carnap.io/shared/emeskhid@uci.edu/0landingPage.md">Carnap site</a>) </div>
				<br/>

				<div className="head"> course that I've TAed </div>
				<div className="text">  Foundations of Spacetime Theories <br/> Introduction to Inductive Logic <br/>  The Good Life <br/> Philosophy of Sex <br/> Classical Mythology: The Gods <br/> Introduction to Symbolic Logic <br/> Introduction to Women's Studies <br/> Clinical Psychology <br/> Personality Psychology </div>
				<br/>

				<div className="head"> trainings that I've facilitated at UCI</div>
				<div className="text">  TA Professional Development Program (2020, 2021, 2022, 2023)  <br/>
				Workshop on Course Design (2021, 2022) <br/>
				Summer Graduate Fellow Training (2022) </div>
				<br/> 
				
				<div className="head"> other trainings that I've facilitated</div>
				<div className="text">  How to bring trauma-informed practices to your classroom (2023, University of Colorado, Boulder)  </div>
			</div>
		</section>
	);
}
