import SimulationState, { SimulationParticle } from "./SimulationState";
import Vector from "./Vector";

// How many particles to spawn
const numParticles = 20;
// What percentage of the top-right anchored box of the screen to spawn them in
const regionPercentage = 0.05;
export default class SimulationInitializer {
	static initialize(
		state: SimulationState,
		canvas: HTMLCanvasElement
	){
		this.initializeParticles(state);
		this.bindCursor(state, canvas);
	}

	private static initializeParticles(
		state: SimulationState
	){
		const getPosRatio = () =>
			(Math.random() * regionPercentage) 
			+ (1 - regionPercentage);
			
		for (let i = 0; i < numParticles; i++){
			const x = getPosRatio() * state.width;
			const y = getPosRatio() * state.height;
			state.particles.push(new SimulationParticle(x,y));
		}
	}

	private static bindCursor(
		state: SimulationState,
		canvas: HTMLCanvasElement
	){
		const canvasSize = new Vector();
		const cursorPos = new Vector();
		document.addEventListener('mouseleave', e => {
			state.clearCursor();
		});
		document.addEventListener('mousemove', e => {
			const rect = canvas.getBoundingClientRect();
			Vector.set(canvasSize, rect.width, rect.height);
			Vector.set(cursorPos, e.clientX - rect.x, e.clientY - rect.y);
			if (
				cursorPos.x < 0
				|| cursorPos.y < 0
				|| cursorPos.x > canvasSize.x
				|| cursorPos.y > canvasSize.y
			) {
				state.clearCursor();
			} else {
				state.setCursor(cursorPos.x, canvasSize.y - cursorPos.y);
			}
		});
	}
}