import "./PageSection.scss";

export default function PageSection({
	className,
	children
}: {
	className?: string,
	children: JSX.Element | JSX.Element[]
}){
	return (
		<div className={`page-section ${className}`}>
			{children}
		</div>
	);
}