import SectionHeader from "../Shared/SectionHeader";
import "./AboutResearch.scss";

export default function AboutResearch() {
	return (
		<section className="about-research" id="about-research">
			<div className="content">
				<SectionHeader
					number={2}
					title="Research"
				/>
				<div className="container card-container">
					<div className="row gy-4">
						<CardWrapper>
							<div className="research-card one">
								<div>foundations of spacetime theories</div>
								<div>
									In my dissertation, I investigate underdetermination in our theories of gravity. In a <a href="https://arxiv.org/abs/2304.11248"> forthcoming paper,</a> Jim Weatherall and I formulate a novel non-relativistic theory of gravity with torsion. I have since studied the relation between this novel non-relativistic theory and Teleparallel Gravity, a relativistic theory of gravity with torsion that is empirically equivalent to General Relativity (manuscript in preparation). Jim Weatherall and I have also investigated the (in)equivalence of General Relativity and Teleparallel Gravity in more detail (manuscript in preparation). 
								</div>
							</div>
						</CardWrapper>
						<CardWrapper>
							<div className="research-card two">
								<div>use of simulations in astrophysics</div>
								<div>
									I have a manuscript in preparation about the use of "physics-informed" machine learning. I have also been part of a collaboration in which we investigated the use of code comparisons to increase trust in simulation results. The scientific results of this collaboration are published in <a href="https://academic.oup.com/mnras/article/513/2/2600/6569716?guestAccessKey=1f0834f4-fa41-4360-8e21-3790151c84f4"> Monthly
										Notices of the Royal Astronomical Society</a> while the philosophical reflection on the methodology is published in <a href="https://link.springer.com/book/10.1007/978-3-031-26618-8">Philosophy of Astrophysics</a>, edited volume. <br></br>
									Finally, I have argued that the use of
									"black-boxes" need not
									undercut scientific
									understanding (published in <a href="https://link.springer.com/article/10.1007/s10670-021-00434-5">Erkenntnis</a>).
								</div>
							</div>
						</CardWrapper>
						<CardWrapper>
							<div className="research-card three">
								<div>pedagogy</div>
								<div>
									I am part of an ongoing project investigating feminist- and trauma-informed approaches to teaching logic and philosophy of physics. I have also coauthored papers about research-informed pedagogy in and for philosophy (in
									<a href="https://www.pdcnet.org/aaptstudies/content/aaptstudies_2019_0005_0169_0199"> AAPT: Studies in Pedagogy</a>),
									ameliorating the
									underrepresentation of
									women in philosophy (in
									<a href="https://ojs.lib.uwo.ca/index.php/fpq/article/view/3107/"> Feminist Philosophy
										Quarterly</a>), as well as
									student-faculty partnerships
									(in <a href="https://www.centerforengagedlearning.org/books/power-of-partnership/section-one/chapter-2/">The Power of Partnership</a>,
									collected volume)
								</div>
							</div>
						</CardWrapper>
					</div>
				</div>
			</div>
		</section>
	);
}

function CardWrapper({
	children
}: {
	children: any
}) {
	return (
		<div className="col-md-10 col-sm-12 mx-auto">
			{children}
		</div>
	);
}