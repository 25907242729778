import Simulator from "./Simulator";

export default class SimulationLoop {
	
	private simulator: Simulator;
	private stopped: boolean = false;

	constructor(
		simulator: Simulator
	){
		this.simulator = simulator;
	}

	run(){
		//this.runTimeout();
		setTimeout(() => {
			this.runAnimationFrame();
		}, 100);
	}

	stop(){
		this.stopped = true;
	}
	
	private runTimeout() {
		const self = this;
		let safteyCount = 0;
		let lastTime = Date.now();
		
		queue();
		function queue() {
			if (safteyCount++ < 1000) {
				//console.log(`Simulation step ${safteyCount}`);
				setTimeout(() => {
					const time = Date.now();
					const dt = time - lastTime;
					lastTime = time;
					self.step(dt, queue);
				}, 10);
			}
		}
	}

	private runAnimationFrame() {
		const self = this;
		let lastTime = Date.now();

		queue();
		function queue() {
			window.requestAnimationFrame(_ => {
				const time = Date.now();
				const dt = Math.min(time - lastTime, 50);
				lastTime = time;
				self.step(dt, queue)
			});
		}
	}

	private step = (
		deltaTimeMs: number | null,
		next: () => void
	) => {
		if (deltaTimeMs && !isNaN(deltaTimeMs) && deltaTimeMs > 0) {
			this.simulator.tick(deltaTimeMs);
		}
		if (!this.stopped){
			next();
		}
	}
}