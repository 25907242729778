import "./Footer.scss";

export default function Footer(){
	return (
		<section className="footer" id="footer">
			<div className="top"> <a href="/CV.pdf" download="HelenMeskhidzeCV.pdf"> CV </a> </div>
			<div className="fade1"><a href="mailto:emeskhidze@fas.harvard.edu">email</a></div>
			<div className="fade2"><a href=" https://philpeople.org/profiles/helen-meskhidze-1">PhilPapers</a></div>
			<div className="bottom"><a href="https://scholar.google.com/citations?hl=en&user=hl8vu4UAAAAJ&view_op=list_works&sortby=pubdate">Google Scholar</a></div>

		</section>
	);
}