import Simulation from "./Simulation/Simulation";
import "./SplashPage.scss";

export default function SplashPage(){

	function scrollToAboutMe(){
		window.location.href = "/#about-me";
	}

	return (
		<div className="splash-page">
			<div className="paralax">
				<div className="paralax-content">
					<div className="background"></div>
					<Simulation/>
					<div className="content">
						<h1>Helen Meskhidze</h1>
						<p className="subtitle">I'm a philosopher of science at the black hole initiative</p>
						<hr/>
						<div className="btn-row">
							<button className="btn btn-light" onClick={scrollToAboutMe}>Learn More</button>
							<a className="btn btn-light" target="_blank" href="mailto:emeskhidze@fas.harvard.edu"> Contact </a> 
							<a className="btn btn-light" target="_blank" href="/CV.pdf" download="HelenMeskhidzeCV.pdf"> CV </a> 

						</div>
					</div>
				</div>
			</div>
		</div>
	);
}