import SimulationRenderer from "./SimulationRenderer";
import SimulationState, { SimulationParticle } from "./SimulationState";
import Vector from "./Vector";


const simulationSpeed = 0.0005;
const repulsionDistanceScaleFactor = 1 / 2;

export default class Simulator {

	private renderer: SimulationRenderer;
	private state: SimulationState;
	private forceGc = new ForceCalculationGarbageBag();
	private applyGc = new ApplyPhysicsUpdateGarbageBag();

	constructor(
		renderer: SimulationRenderer,
		state: SimulationState
	){
		this.renderer = renderer;	
		this.state = state;
	}

	tick(dt: number){
		this.updatePhysics(dt * simulationSpeed);
		this.renderer.render(this.state);
	}

	private updatePhysics(dt: number){

		Vector.set(this.applyGc.boundaryMin, this.state.particleRadius, this.state.particleRadius);
		Vector.set(this.applyGc.boundaryMax, this.state.width - this.state.particleRadius, this.state.height - this.state.particleRadius);

		for (const particle of this.state.particles){
			this.updateForce(particle, dt, this.forceGc);
		}
		for (const particle of this.state.particles){
			this.applyPhysicsUpdates(particle, dt, this.applyGc);
		}
	}

	applyPhysicsUpdates(
		particle: SimulationParticle,
		dt: number,
		gc: ApplyPhysicsUpdateGarbageBag
	) {
		if (!Vector.isValid(particle.force)) {
			return;
		}
		Vector.multiplyScalar(particle.force, dt, gc.deltaV);
		Vector.add(gc.deltaV, particle.velocity, particle.velocity);

		// Reflective boundary condition
		const boundaryMin = gc.boundaryMin;
		const boundaryMax = gc.boundaryMax;
		Vector.multiplyScalar(particle.velocity, dt, gc.deltaP);
		Vector.add(particle.position, gc.deltaP, particle.position);

		if (particle.position.x < boundaryMin.x){
			particle.velocity.x *= -1;
			const overshoot = boundaryMin.x - particle.position.x;
			particle.position.x = boundaryMin.x + overshoot;
		} else if (particle.position.x > boundaryMax.x){
			particle.velocity.x *= -1;
			const overshoot = boundaryMax.x - particle.position.x;
			particle.position.x = boundaryMax.x + overshoot;
		}

		if (particle.position.y < boundaryMin.y){
			particle.velocity.y *= -1;
			const overshoot = boundaryMin.y - particle.position.y;
			particle.position.y = boundaryMin.y + overshoot;
		} else if (particle.position.y > boundaryMax.y){
			particle.velocity.y *= -1;
			const overshoot = boundaryMax.y - particle.position.y;
			particle.position.y = boundaryMax.y + overshoot;
		}
	}

	updateForce(
		particle: SimulationParticle,
		dt: number,
		gc: ForceCalculationGarbageBag
	) {
		const numParticles = this.state.particles.length;
		const forceConst = Math.pow(10, 9) / numParticles;
		const repulsionConst = Math.pow(10, 9) / numParticles;
		
		const pointPos = particle.position;
		Vector.zero(particle.force);
		for (const other of this.state.particles){

			if (particle == other){
				continue;
			}

			const otherPos = other.position;

			Vector.subtract(pointPos, otherPos, gc.offset);
			const offsetMag = Math.max(
				Vector.magnitude(gc.offset),
				10
			);
			Vector.normalize(gc.offset, gc.offset);

			const gravForceMagnitude = -dt * forceConst / Math.pow(offsetMag, 2);
			Vector.multiplyScalar(gc.offset, gravForceMagnitude, gc.forceTerm);
			Vector.add(gc.forceTerm, particle.force, particle.force);

			const replusiveForceMag = dt * repulsionConst / Math.pow(
				offsetMag / (repulsionDistanceScaleFactor * this.state.particleRadius),
				3
			);
			Vector.multiplyScalar(gc.offset, replusiveForceMag, gc.forceTerm);
			Vector.add(gc.forceTerm, particle.force, particle.force);

		}

		const velocity = particle.velocity;
		if (Vector.magnitude(velocity) > this.state.width / 3) {
			Vector.multiplyScalar(velocity, -10, gc.forceTerm);
			Vector.add(gc.forceTerm, particle.force, particle.force);
		}

		if (this.state.cursor) {
			Vector.subtract(this.state.cursor, particle.position, gc.offset);
			const offsetMag = Math.max(Vector.magnitude(gc.offset), 100);
			Vector.normalize(gc.offset, gc.offset);

			const cursorForceMag = 100 * dt * forceConst / Math.pow(offsetMag, 2);
			Vector.multiplyScalar(gc.offset, cursorForceMag, gc.forceTerm);
			Vector.add(gc.forceTerm, particle.force, particle.force);
		}
	}
}

class ForceCalculationGarbageBag {
	offset: Vector = new Vector();
	forceTerm: Vector = new Vector();
	netForce: Vector = new Vector();
}

class ApplyPhysicsUpdateGarbageBag {
	deltaV = new Vector();
	deltaP = new Vector();
	nextP = new Vector();
	boundaryMin = new Vector();
	boundaryMax = new Vector();
}