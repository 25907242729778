import SimulationState from "./SimulationState";
import Vector from "./Vector";

export default class SimulationRenderer {

	private canvas: HTMLCanvasElement;
	private ctx: CanvasRenderingContext2D;
	private particleSize: number = 0;
	
	constructor(
		canvas: HTMLCanvasElement
	){
		this.canvas = canvas;
		const ctx = this.canvas.getContext('2d');
		if (ctx != null){
			this.ctx = ctx;
		} else {
			throw new Error("Failed to initialize canvas context");
		}
	}

	render(state: SimulationState){
		this.ctx.canvas.width = state.width;
		this.ctx.canvas.height = state.height;

		for (const particle of state.particles){
			//const color = particle
			const speed = Vector.magnitude(particle.velocity);
			//const r = speed;// * 255 / 20;
			const r = 255 * 4 * Math.abs(particle.velocity.x / state.width);
			const g = 255 * 4 * Math.abs(particle.velocity.y / state.height);
			const mag = Vector.magnitude(particle.velocity);
			let a = Math.log(mag / 100);
			a = Math.max(0, a);
			//const color = `rgba(${0}, ${g}, ${r}, ${a})`;
			const color = "white";
			this.drawCircle(state, particle.position, state.particleRadius, color, 0.5);
		}
		if (state.cursor){
			this.drawCircle(state, state.cursor, state.particleRadius * 1.5, "white", 0.5);
		}
	}

	clear() {
		this.ctx.clearRect(0, 0, 100000, 1000000);
	}

	drawCircle(
		state: SimulationState,
		point: Vector,
		radius: number,
		color: string,
		alpha: number
	) {
		color = color || "white";
		alpha = alpha || 1;

		this.ctx.fillStyle = color;
		this.ctx.globalAlpha = alpha;

		this.ctx.beginPath();
		this.ctx.arc(point.x, state.height - point.y, radius, 0, 2 * Math.PI, true);
		this.ctx.fill();

		this.ctx.fillStyle = "white";
		this.ctx.globalAlpha = 1;
	}

	drawLine(state: SimulationState, start: Vector, end: Vector) {
		this.ctx.beginPath();
		this.ctx.moveTo(start.x, state.height - start.y);
		this.ctx.lineTo(end.x, state.height - end.y);
		this.ctx.stroke();
	}

	drawPoly(state: SimulationState, points: Vector[], fill: boolean) {
		this.ctx.beginPath();
		let hasMoved = false;
		for (let point of points) {
			if (!hasMoved) {
				this.ctx.moveTo(point.x, state.height - point.y);
				hasMoved = true;
			} else {
				this.ctx.lineTo(point.x, state.height - point.y);
			}
		}
		if (fill) {
			this.ctx.fill();
		} else {
			this.ctx.stroke();
		}
	}

}