import Vector from "./Vector";

export default class SimulationState {


	particles: SimulationParticle[] = [];


	get width() { return this.canvas.clientWidth; }
	get height() { return this.canvas.clientHeight; }
	get particleRadius() { return Math.max(this.width, this.height) * 0.01; }
	get size() {
		Vector.set(this._size, this.width, this.height);
		return this._size;
	}
	get cursor() {
		if (this._isMouseOver){
			return this._cursor;
		} else {
			return null;
		}
	}

	private canvas: HTMLCanvasElement;
	private _size = new Vector();
	private _isMouseOver = false;
	private _cursor = new Vector();

	constructor(
		canvas: HTMLCanvasElement
	){
		this.canvas = canvas;
	}

	clearCursor(){
		this._isMouseOver = false;
	}
	
	setCursor(x: number, y: number){
		this._isMouseOver = true;
		Vector.set(this._cursor, x, y);
	}
}

export class SimulationParticle {
	position: Vector;
	velocity: Vector = new Vector();
	force: Vector = new Vector();

	constructor(
		x: number,
		y: number
	) {
		this.position = new Vector(x, y);
	}
}