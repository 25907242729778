export default class Vector {

	x: number;
	y: number;

	constructor(
		x: number = 0,
		y: number = 0
	) {
		this.x = x;
		this.y = y;
	}

	static zero(a: Vector){
		a.x = 0;
		a.y = 0;
	}

	static set(a: Vector, x: number, y: number){
		a.x = x;
		a.y = y;
	}

	static isValid(a: Vector){
		return !isNaN(a.x) && !isNaN(a.y) && isFinite(a.x) && isFinite(a.y);
	}

	static add(a: Vector, b: Vector, result: Vector){
		result.x = a.x + b.x;
		result.y = a.y + b.y;
	}

	static subtract(a: Vector, b: Vector, result: Vector){
		result.x = a.x - b.x;
		result.y = a.y - b.y;
	}

	static multiply(a: Vector, b: Vector, result: Vector){
		result.x = a.x - b.x;
		result.y = a.y - b.y;
	}

	static multiplyScalar(a: Vector, s: number, result: Vector){
		result.x = a.x * s;
		result.y = a.y * s;
	}

	static magnitude(a: Vector){
		return Math.sqrt(a.x * a.x + a.y * a.y);
	}

	static normalize(a: Vector, out: Vector){
		const mag = Vector.magnitude(a);
		out.x = a.x / mag;
		out.y = a.y / mag;
	}
}